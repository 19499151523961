<template>
	<div>
		<PageTitle title="病历上传"></PageTitle>
		<p class="tips" style="text-align: center;">请拍摄并上传患者的病历资料</p>
		<List>
			<van-uploader v-model="formData" :after-read="afterRead" multiple @delete="afterDelete">
				<ListItem :leftWidth="35" :rightWidth="63" v-if="addShow1 === 1">
					<template v-slot:left>
						<img src="../assets/medicalrecord1.png" />
					</template>
					<template v-slot:right>
						<p style="font-size:13px;margin-top:10px">拍摄要求：</p>
						<p>请拍摄上传患者本人在医院就诊的病历封面和病历资料（1张及以上）；<br>拍摄时确保，封面与填写医院一致，能够看清文字和照片。
						</p>
					</template>
					<template v-slot:bottom>
						<p>拍摄病历封面、正文照片</p>
					</template>
				</ListItem>
			</van-uploader>
		</List>
		<footer>
			<van-button type="primary" block style="margin-bottom:10px" @click="uploadConfirm">确认上传</van-button>
		</footer>
	</div>
</template>

<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import {
		AddAccount,
		UploadFileBase64,
		UpdateBRBL,
		QueryAccount,
		SetDefaultAccount
	} from '@/api/user.js';

	export default {
		components: {
			PageTitle,
			List,
			ListItem,
		},
		data() {
			return {
				addShow1: 1,
				addShow2: 2,
				formData: [],
				postData: {
					userName: '',
					userNum: '',
					userAddr: '',
					userMz: '',
					userSex: '',
					userBirth: '',
					sjhm: '',
					type: ''
				}
			}
		},
		mounted: function() {
			// this.tips();
			let patientInfo = JSON.parse(localStorage.getItem('IdCardInfo'));
			if (patientInfo) {
				this.postData.sjhm = this.$store.getters.userInfo.PhoneNumber.toString();
				this.postData.userName = patientInfo.userName;
				this.postData.userNum = patientInfo.userNum;
				this.postData.userAddr = patientInfo.userAddr;
				this.postData.userMz = patientInfo.userMz;
				this.postData.userSex = patientInfo.userSex;
				this.postData.userBirth = patientInfo.userBirth;
			}
		},
		methods: {
			afterRead() {
				// 此时可以自行将文件上传至服务器
				if (this.formData.length > 0) {
					this.addShow1 = 0;
					let that = this;
					for (let [index, file] of new Map(this.formData.map((file, index) => [index, file]))) {
						let fileObj = file
							.file; //原文件   file是我用vue-vant里的组件，里边有file(原文件)和content(base64)  其它写法自行百度，都是可以拿到原文件的
						if (fileObj && that.formData[index].content.length && that.formData[index].content.length > 1024 *
							512) { //判断是否大于0.5MB  524288B
							let cal = 1024 * 512 / that.formData[index].content.length; //计算比例，也可以自己定义比例 0.5等等
							let reader = new FileReader();
							reader.readAsDataURL(fileObj);
							reader.onload = function() {
								let image = new Image(); //新建一个img标签（不嵌入DOM节点，仅做canvas操作)
								image.src = file.content; //file是我用vant里的组件，里有转好的base64格式content
								image.onload = function() { //图片加载完毕后再通过canvas压缩图片，否则图片还没加载完就压缩，结果图片是全黑的
									let canvas = document.createElement('canvas'), //创建一个canvas元素
										context = canvas.getContext('2d'), //context相当于画笔，里面有各种可以进行绘图的API
										imageWidth = image.width * cal, //压缩后图片的宽度
										imageHeight = image.height * cal //压缩后图片的高度
									canvas.width = imageWidth //设置绘图的宽度
									canvas.height = imageHeight //设置绘图的高度
									//使用drawImage重新设置img标签中的图片大小，实现压缩。drawImage方法的参数可以自行查阅W3C
									context.drawImage(image, 0, 0, imageWidth, imageHeight)
									//使用toDataURL将canvas上的图片转换为base64格式
									fileObj = canvas.toDataURL('image/jpeg');
									that.formData[index].content = fileObj;
								}
							}
						}
					}
				}
			},
			afterDelete() {
				if (this.formData.length == 0) {
					this.addShow1 = 1;
				}
			},
			tips() {
				this.$dialog.confirm({
					title: '温馨提示',
					message: '因国家相关法律法规要求\n精神卫生网上诊疗，需具备历史病历\n若您是首次问诊\n建议线下医院咨询/电话咨询',
					confirmButtonText: '关于我们',
					cancelButtonText: '继续'
				}).then(() => {
					// on confirm
					window.location.href = 'https://www.kn120.com/aboutShow.aspx?cla=2';
				}).catch(() => {
					// on cancel

				});
			},
			uploadConfirm() {
				let data = {
					"brxm": this.postData.userName,
					"brxb": this.postData.userSex,
					"sfzh": this.postData.userNum,
					"brxz": "1000",
					"xzmc": "自费",
					"sjhm": this.postData.sjhm,
					"address": this.postData.userAddr,
					"birthday": this.postData.userBirth,
					"mz": this.postData.userMz,
					"zfbid": this.$store.getters.userInfo.UserId
				};
				if (this.formData.length == 0) {
					// this.$toast('请先上传病历封面照片')
					AddAccount(data).then(
						() => {
							QueryAccount(1, this.$store.getters.userInfo.PhoneNumber, this.$store.getters.userInfo.UserId).then(
								(cardList) => {
									if (cardList.length == 1) {
										SetDefaultAccount(cardList[0].ckhm, this.$store.getters.userInfo.UserId)
											.then(
												() => {},
												() => {}
											);
									}
								},
								() => {}
							);
							this.$store.dispatch('pageData/clearInfo'); //清空全局变量
							this.$store.dispatch('pageData/setInfo', data);
							// this.$router.push("/accountJudgment/0/0/0");
							this.$router.go(-2);
						},
						() => {}
					);
				} else {
					AddAccount(data).then(
						(result) => {
							UploadFileBase64(this.formData, 2, this.postData.userName + "病历照片", result).then(
								(filepath) => {
									UpdateBRBL(this.$store.getters.userInfo.UserId, result, filepath[0]).then(
										() => {
											this.$store.dispatch('pageData/clearInfo'); //清空全局变量
											this.$store.dispatch('pageData/setInfo', data);
											// this.$router.push("/accountJudgment/0/0/0");
											this.$router.go(-2);
										},
										() => {},
									);
								}, () => {}
							);
							QueryAccount(1, this.$store.getters.userInfo.PhoneNumber, this.$store.getters.userInfo
								.UserId).then(
								(cardList) => {
									if (cardList.length == 1) {
										SetDefaultAccount(cardList[0].ckhm, this.$store.getters.userInfo.UserId)
											.then(
												() => {},
												() => {}
											);
									}
								},
								() => {}
							);
						},
						() => {}
					);
				}
			}
		}
	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	.van-uploader {
		display: block;
	}

	.tips {
		font-size: @font-size-lg;
		color: @green;
	}

	.right p {
		font-size: @font-size-sm;
		margin: 2px 0 2px;
	}

	.bottom p {
		border-top: 1px solid @green;
		width: 80%;
		margin: 10px 10%;
		text-align: center;
		font-size: @font-size-lg;
		padding-bottom: 10px;
	}

	footer {
		bottom: 5px;
		position: relative;
		width: 80%;
		margin: 5px 10%;
		text-align: center;
	}
</style>
